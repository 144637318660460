<template>
  <b-container
    class="text-white containerBg paddingTopBottomLarge fontStandard"
    id="Smooth-home"
    :style="this['events/handleMinHeight']"
    fluid
  >
    <div class="sticky-top top60px text-right" style="z-index: 1">
      <b-badge variant="warning" class="shadow">
        <b-button
          squared
          variant="warning"
          v-for="(v, i) in fastNav"
          :key="i"
          @click="$tools.handleSmooth(v.link)"
          :size="isMobileAspect ? 'md' : 'sm'"
        >
          <i :class="v.fa" />
          {{ isMobileAspect ? '' : v.text }}
        </b-button>
      </b-badge>
    </div>

    <!-- <div class="sticky-top">
      <div class="smoothNavigation top100px text-right">
        <i class="fas fa-arrow-circle-up" @click="handleSmooth('#Smooth-home')" />
      </div>
    </div>-->

    <b-row class="text-left paddingTopBottomLarge" id="Smooth-me">
      <b-col cols="0" md="1" lg="2"></b-col>
      <b-col cols="12" md="10" lg="8" class="text-left">
        <section
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-duration="1500"
          data-aos-easing="ease-in-sine"
        >
          <h1 class="text-warning fontCustom mt-5">Mon parcours</h1>
          <article class="fontStandard">
            <p>
              <b-img-lazy
                :src="pictures.identity"
                alt="Moi"
                class="scaleUpOnHover"
                width="330"
                thumbnail
                rounded
                fluid
              />
            </p>
            <span class="resizeH4Carousel">
              <WelcomeMessages
                textColor="#ffc107"
                beforeMessage=" "
                afterMessage=" "
                :messages="multiMessages"
              />
            </span>
          </article>
        </section>

        <article
          class="fontStandard mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4 data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <strong
              class="text-warning arrow"
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              >Expérience personnelle</strong
            >
          </h4>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            L'informatique est pour moi une passion depuis mes 14ans, tout
            commença avec le langage Basic 1.0 sur Amstrad CPC6128, j'avais
            réalisé un petit programme de stockage de données, et j'avais essayé
            de réaliser un jeu de plateforme.
          </p>

          <p>
            <b-img-lazy
              :src="pictures.amstradcpc6128"
              alt="Amstrad CPC6128"
              class="my-3 scaleUpOnHover"
              width="512"
              thumbnail
              rounded
              fluid
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
            />
          </p>
          <p data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            En l'an 2000 j’ai été technicien micro informatique, puis technicien
            réseau, en souhaitant évoluer comme technicien datacentre mais le
            marché du travail était difficile sur la cote d'azur.
          </p>
        </article>

        <article
          class="fontStandard mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h5 data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <strong
              class="text-warning arrow"
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              >2004 - Conception d'une boutique en ligne comme eBay</strong
            >
          </h5>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            En 2004, une opportunité professionnelle s'est présentée à moi,
            celle de réaliser un site web E-Commerce sur le même thème que eBay,
            à l'unique différence que le site web devait contenir une option
            permettant le troc (Échange d'un objet contre un autre objet
            diffèrent mais d'une valeur approximative).
          </p>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            Les moyens mis à disposition étaient, un serveur dédié hébergé chez
            un professionnel, le langage PHP4 pour concevoir les pages web, le
            stockage des données avec MySQL, bien que ce projet ait été initié
            et du code source fut généré, le projet a été abandonné 1 an plus
            tard, cependant cette expérience m'aura permise de commencer la
            conception d'un site web dynamique, ce fut mon expérience la plus
            marquante.
          </p>
        </article>

        <article
          class="fontStandard mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h5 data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <strong
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              class="text-warning arrow"
              >2018 - Expérience avec le framework Vue.JS et le langage
              JavaScript</strong
            >
          </h5>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            Bien que j'ai contribué sur des petits projets diverses en
            développement, En 2018, j'ai été bénévole dans un projet ambitieux
            et complexe, j'ai réalisé une plateforme de jeu de rôle conçu autour
            de jeu GTA5 et propulsé par RageMP qui est un serveur.
          </p>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            Pour la conception, j'ai utilisé le langage JavaScript pour la
            partie Backend, et le framework Vue.JS pour la partie frontend. Ce
            projet fut un succès et a permis d'avoir plus de 250 joueurs
            simultanés, il a été en production pendant 1 an.
          </p>
        </article>

        <article
          class="fontStandard mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h5 data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <strong
              class="text-warning arrow"
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              >2019-2020 - formation et obtention d'un diplôme</strong
            >
          </h5>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            J'ai effectué une formation comme développeur web et web mobile au
            Greta du lycée du Parc impérial de Nice.
          </p>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            Cette formation m'a permise d'acquérir les connaissances nécessaires
            à la création d'une base de données MySQL, au maquettage fonctionnel
            d'application web ou mobile, mais également à l'apprentissage
            approfondi du JavaScript avec Node.js, Vue.JS.
          </p>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            La mise en pratique du code PHP, et l'apprentissage d'un framework
            PHP comme Symfony.
          </p>

          <p data-aos-anchor-placement="top-bottom" data-aos="fade-right">
            L'aspect SEO a été étudié également et le développement
            d'application sous android avec Android Studio et Nox.
          </p>
        </article>

        <article class="fontStandard">
          <section data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <h6>
              <strong class="text-warning arrow">Stage en entreprise</strong>
            </h6>
            <p>
              Dans la cadre du contexte inédit du Covid-19, comme d'autres
              étudiants, mon stage dans une entreprise de développement a été
              annulé, j'ai été stagiaire pour un centre équestre, dans lequel en
              télétravail, j'ai réalisé un site web à la demande du client et
              aux exigences de celui-ci, la mise en pratique dans ce stage a
              été:
            </p>
          </section>

          <ol>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              L'écoute du client, ses besoins
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              La réalisation du cahier des charges
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              La réalisation d'une maquette fonctionnelle affichant les points
              important du futur site web avec Pencyl project
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              La réalisation du Modèle conceptuel de données, pour réaliser une
              base de données MySQL structurée
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              L'ouverture d'un projet sur GIT, afin d'ajouter tous les tickets
              nécessaire au projet, placés dans une Milestone
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              Création du projet étape par étape en validant les tickets GIT au
              fur et à mesure du projet
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              Développement d'une API écrite en PHP orienté objet avec un
              stockage des données MySQL
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              Développement de la partie front end Vue avec consommation de
              l'API avec le plugins Axios
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              Démonstration au client
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              Correction des bugs
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              Mise en production du projet
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              Présentation du projet en détails devant le Jury du Greta pendant
              1 heure avec une documentation détaillée de 39 pages, félicitation
              du Jury et niveau présenté comme étant un développeur fullstack
              junior
            </li>
            <li data-aos-anchor-placement="top-bottom" data-aos="fade-right">
              <i class="fas fa-award"></i> Obtention du diplôme, Titre
              professionnel développeur web niveau III (BAC+2)
              <i class="fas fa-award"></i>
              <br />
              <b-img-lazy
                :src="pictures.diplome"
                alt="Diplome"
                class="my-3 scaleUpOnHover mt-3"
                width="500"
                rounded
                data-aos-anchor-placement="top-bottom"
                data-aos="slide-up"
                fluid
              />
            </li>
          </ol>

          <article
            class="fontStandard"
            data-aos-anchor-placement="top-bottom"
            data-aos="slide-up"
          >
            <h4>
              <strong class="text-warning arrow"
                >Mon logo création de projets, Naskot</strong
              >
            </h4>
            <p>
              <b-img-lazy
                :src="naskotlogo_b"
                alt="Logo"
                class="my-3 scaleUpOnHover"
                width="450"
                rounded
                fluid
              />
            </p>
          </article>

          <article
            class="fontStandard"
            data-aos-anchor-placement="top-bottom"
            data-aos="slide-up"
          >
            <h4>
              <strong class="text-warning arrow"
                >Voici le QR Code de mon CV</strong
              >
            </h4>
            <p>
              <QrCode :value="commons.qrcode.cv" color="#163a58" />
            </p>
          </article>
        </article>
      </b-col>
      <b-col cols="0" md="1" lg="2"></b-col>
    </b-row>

    <b-row
      class="text-left fontStandard paddingTopBottomLarge bg-light text-blue"
      id="Smooth-dev"
      data-aos-anchor-placement="top-bottom"
      data-aos="slide-up"
    >
      <b-col cols="0" md="1" lg="2"></b-col>
      <b-col cols="12" md="10" lg="8" class="text-left">
        <h1
          class="text-info fontCustom mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="flip-up"
        >
          Cette Application Web a été Construite avec Vue
        </h1>

        <article
          class="fontStandard mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong class="arrow"
              >Environnement Vue CLI pour créer une application SPA:</strong
            >
          </h4>
          <p class="align-bottom">
            <b-img-lazy
              :src="pictures.logo_vue"
              alt="Vue Logo"
              class="d-none d-xl-block float-left m-2 scaleUpOnHover"
              width="60"
              rounded
              fluid
            />Vue est un framework gratuit crée par Evan You en 2014 et maintenu
            par une équipe de passionnés.
          </p>
          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            Vue CLI est une interface en ligne de commande permettant d'utiliser
            le système de paquetage NPM
          </p>

          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            et donc de pouvoir ajouter les contributions d'autres développeurs.
          </p>

          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            Vue CLI est un environnement conçu pour fabriquer des applications
            performantes de type SPA
            <em>(Single Page Application ou Application Web Monopage)</em>
            ,
          </p>
          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            ce qui signifie que toutes les pages se situeront dans une seule
            page HTML,
          </p>
          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            une SPA sera considérée comme étant de la programmation coté client
            <em>(Frontend)</em>.
          </p>
        </article>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong
              class="arrow"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              >Une meilleur expérience utilisateur UX:</strong
            >
          </h4>
          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            Vue permet de réaliser une application dite réactive, c'est à dire
            sans rafraîchissement de page, l'application Vue consommera
            éventuellement une API en arrière plan <em>(backend)</em> pour
            mettre à jour ses données toujours sans rafraîchissement de page.
          </p>
        </article>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong
              class="arrow"
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              >Un environnement de travail conçu pour les développeurs:</strong
            >
          </h4>
          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            Avec Vue CLI, nous disposons d'un environnement de travail de
            développement pour créer intégralement une application Vue, le
            principal avantage de cette méthode de travail, est de pouvoir créer
            sous forme de composants réutilisables (.vue) comme si on fabriquait
            des briques de construction qu'on réutilise sans cesse.
          </p>
          <ol data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <li>Vous obtenez plus de maintenabilités sur la durée.</li>
            <li>Parfait pour travailler en équipe de développeurs.</li>
            <li>Tous les points forts du javascript.</li>
            <li>Gestionnaire de paquetages NPM qui est riche en contenu</li>
          </ol>

          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            Vue est en passe d'être le framework javascript le plus performant,
            plus léger, plus simple à utiliser que ses concurrents lorsque
            l'application est terminée on build l'application avec webpack pour
            créer une version en production distribuable, comme l'exemple
            ci-dessous.
            <b-img-lazy
              :src="pictures.webpackbuild"
              alt="Build avec Vue Webpack"
              class="my-3 scaleUpOnHover mt-0"
              width="800"
              thumbnail
              rounded
              fluid
              data-aos-anchor-placement="top-bottom"
              data-aos="zoom-in"
            />
          </p>
        </article>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong class="arrow"
              >La SEO, le seul inconvénient à observer des le début du
              projet:</strong
            >
          </h4>

          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            Dans le cas d'une application Vue à référencer, pour comprendre le
            principal inconvénient des applications SPA (Single Page
            Application), il faut comprendre comment fonctionne l'indexation
            d'un moteur de recherche comme Google.
          </p>

          <p data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            Pour indexer votre site ou application web, Google va effectuer un
            passage en 2 étapes:
          </p>

          <ol data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <li>
              Le premier passage va récupérer tout le contenu HTML de vos pages.
              <b-badge
                variant="warning"
                class="text-wrap"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                Inutile dans le cas d'une Application Vue, car tout est écrit en
                javascript.
              </b-badge>
            </li>
            <li>
              Le second passage va traduire le contenu JavaScript de vos pages,
              dans un délai imparti.
              <ul>
                <li>
                  <b-badge
                    variant="success"
                    class="text-wrap"
                    data-aos="fade-right"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Cette étape va permettre de récupérer correctement vos pages
                    écrites en JavaScript
                  </b-badge>
                </li>
                <li>
                  <b-badge
                    variant="warning"
                    class="text-wrap"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Le délai imparti signifie que Google vous offre un temps
                    d'indexation, il est essentiel à ce moment de proposer des
                    pages fluides et rapide à charger.
                  </b-badge>
                </li>
                <li>
                  <b-badge
                    variant="danger"
                    class="text-wrap"
                    data-aos="fade-down"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Dans le cas ou il y aurait trop de pages, il faudrait
                    s'orienter vers une autre solution comme NuxtJS
                  </b-badge>
                </li>
              </ul>
            </li>
          </ol>
        </article>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong class="arrow"
              >Ma solution pour améliorer la SEO d'une application Vue
              SPA:</strong
            >
          </h4>
          <ol>
            <li>
              Désactiver le mode Hashtag du router Vue, et configurer le mode
              History.
            </li>
            <li>Utiliser des images compressées au format .Webp</li>
            <li>
              Ajouter pour chaque composants, une description dans une balise
              html
              <strong>meta name description</strong>, afin de spécifier au
              moteur de recherche la description de la page en cours.
            </li>
            <li>
              Utiliser VueX pour avoir un store de données ou aussi appelé
              source de vérité, afin d'avoir une donnée partageable sur
              plusieurs composants.
            </li>
            <li>
              Alléger le nombre de page Vue, afin que Google puisse référencer
              la totalité des pages.
            </li>
            <li>
              Utiliser les Hooks du cycle de vie de Vue, afin de ne pas saturer
              la mémoire du navigateur client
            </li>
            <li>
              Optimiser les pages pour le Mobile First avec Bootstrap ou
              Material design
            </li>
          </ol>
        </article>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong class="arrow">Optimisation des images</strong>
          </h4>
          <p>
            Je détecte si votre navigateur prend en charge le format webp, si
            c'est le cas je charge l'image en webp sinon elle sera chargée en
            png pour être compatible avec les vieux navigateurs
          </p>
          <p>Voici un exemple avec mon Logo personnel</p>
          <b-img-lazy
            :src="naskotlogo_w"
            alt="Logo"
            class="my-3"
            data-aos-anchor-placement="top-bottom"
            data-aos="fade-up"
            rounded
            fluid
          />
          <p data-aos-anchor-placement="top-bottom" data-aos="zoom-right">
            <strong
              :class="[$tools.canUseWebP() ? 'text-success' : 'text-danger']"
              >{{
                $tools.canUseWebP()
                  ? "Votre navigateur est compatible, l'image est en WebP"
                  : "Votre navigateur n'est pas compatible, l'image est en PNG"
              }}</strong
            >
          </p>
        </article>
      </b-col>
      <b-col cols="0" md="1" lg="2"></b-col>
    </b-row>

    <b-row class="text-left paddingTopBottomLarge" id="Smooth-memo">
      <b-col cols="0" md="1" lg="2"></b-col>
      <b-col cols="12" md="10" lg="8" class="text-left">
        <h1
          class="text-warning fontCustom mt-5"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-right"
        >
          Dans cette partie je vais partager avec vous ou tout simplement garder
          en mémoire ce que j'ai découvert
        </h1>

        <article
          class="fontStandard"
          data-aos-anchor-placement="top-bottom"
          data-aos="fade-up"
        >
          <h4>
            <strong
              class="arrow"
              data-aos-anchor-placement="top-bottom"
              data-aos="fade-up"
              >Rendre Vue nativement compatible avec le format d'image
              WebP</strong
            >
          </h4>
          <p>
            WebP est un format d'image matricielle développé et mis à
            disposition du public par Google.
            <br />Il offre une meilleure compression des images et par
            conséquent un chargement de votre application web plus rapide.
          </p>
          <p>
            Le framework Vue CLI est nativement incompatible avec le format
            d'image WebP.
          </p>

          <p>
            Vous pouvez trouver des paquetages sur NPM comme vue-webp-plugin,
            qui permet de convertir une image JPG en WebP,
          </p>

          <p>
            mais cela nécessite d'utiliser de nouveaux attributs comme par
            exemple:
          </p>

          <b-img-lazy
            :src="pictures.vue_webp_plugin"
            alt="https://www.npmjs.com/package/vue-webp-plugin"
            class="scaleUpOnHover"
            width="389"
            rounded
            fluid
          />

          <p>
            Pas pratique, surtout dans le cas ou on ajoute des paquetages
            d'autres développeurs comme un carousel d'images.
          </p>
          <h5>
            Voici la solution la plus simple, nous allons ajouter nativement le
            format WebP dans Vue CLI version 2 ou version 3
          </h5>
          <ol>
            <li>
              Ajouter le paquetage NPM
              <b-link
                href="https://www.npmjs.com/package/image-webpack-loader"
                target="_blank"
                >image-webpack-loader</b-link
              >avec la commande
              <code>npm i image-webpack-loader --save-dev</code>
            </li>
            <li>
              Modifier le fichier webpack.base.conf.js, et modifier la ligne
              pour la prise en charge de WebP
              <p><code>test: /\.(gif|png|jpe?g|svg)$/i,</code> en</p>
              <p>
                <code>test: /.(gif|png|jpe?g|webp|svg)$/i,</code>
              </p>
              <p>
                le reste est optionnel pour le fonctionnement.
              </p>
            </li>
            <li>
              <p>
                Voila c'est terminé, le résultat dans une feuille de style CSS:
              </p>

              <b-img-lazy
                :src="pictures.webp_in_css"
                alt="WebP CSS"
                class="scaleUpOnHover"
                rounded
                fluid
              />

              <p>
                le résultat dans notre objet de données:
              </p>

              <b-img-lazy
                :src="pictures.webp_in_vuedata"
                alt="WebP CSS"
                class="scaleUpOnHover"
                rounded
                fluid
              />
            </li>
          </ol>
          <p></p>
        </article>
      </b-col>
      <b-col cols="0" md="1" lg="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    WelcomeMessages: () => import('@/components/WelcomeMessages'),
    QrCode: () => import('@/components/QrCode'),
  },
  computed: {
    ...mapState(['commons', 'events', 'view_home']),
    ...mapGetters(['events/handleMinHeight']),
    naskotlogo_b() {
      if (this.$tools.canUseWebP()) {
        return require('@/assets/webp/naskot_create_b.webp')
      } else {
        return require('@/assets/img/naskot_create_b.png')
      }
    },
    naskotlogo_w() {
      if (this.$tools.canUseWebP()) {
        return require('@/assets/webp/naskot_create_w.webp')
      } else {
        return require('@/assets/img/naskot_create_w.png')
      }
    },
    pictures() {
      if (this.$tools.canUseWebP()) {
        return {
          logo_vue: require('@/assets/webp/home/logo_vue.webp'),
          webpackbuild: require('@/assets/webp/home/vue_build_webpack.webp'),
          identity: require('@/assets/webp/home/identity.webp'),
          amstradcpc6128: require('@/assets/webp/home/cpc6128.webp'),
          dossier_professionnel: require('@/assets/webp/home/dossier_pro.webp'),
          dossier_projet: require('@/assets/webp/home/dossier_projet.webp'),
          diplome: require('@/assets/webp/home/diplome.webp'),
          vue_webp_plugin: require('@/assets/webp/home/vue-webp-plugin.webp'),
          webp_in_css: require('@/assets/webp/home/webp_in_css.webp'),
          webp_in_vuedata: require('@/assets/webp/home/webp_in_vuedata.webp'),
        }
      } else {
        return {
          logo_vue: require('@/assets/img/home/logo_vue.png'),
          webpackbuild: require('@/assets/img/home/vue_build_webpack.jpg'),
          identity: require('@/assets/img/home/identity.jpg'),
          amstradcpc6128: require('@/assets/img/home/cpc6128.jpg'),
          dossier_professionnel: require('@/assets/img/home/dossier_pro.jpg'),
          dossier_projet: require('@/assets/img/home/dossier_projet.jpg'),
          diplome: require('@/assets/img/home/diplome.png'),
          vue_webp_plugin: require('@/assets/img/home/vue-webp-plugin.jpg'),
          webp_in_css: require('@/assets/img/home/webp_in_css.jpg'),
          webp_in_vuedata: require('@/assets/img/home/webp_in_vuedata.jpg'),
        }
      }
    },
    showHeader() {
      if (this.$route.path === '/') {
        if (this.events.width > 1200) {
          return true
        } else return false
      } else return false
    },
    fastNav() {
      const fastNav = []

      if (this.showHeader) {
        fastNav.push({
          link: '#app',
          fa: 'fas fa-images',
          text: 'Acceuil',
        })
      }

      fastNav.push(
        {
          link: '#Smooth-me',
          fa: 'fas fa-user-circle',
          text: 'Mon parcours',
        },
        { link: '#Smooth-dev', fa: 'fas fa-laptop-code', text: 'Réalisation' },

        { link: '#Smooth-memo', fa: 'fas fa-brain', text: 'Astuces/Memo' },
        { link: '#Smooth-home', fa: 'fas fa-arrow-up', text: 'Top' }
      )

      return fastNav
    },

    isMobileAspect() {
      return this.events.width < 1200 ? true : false
    },
  },
  data() {
    return {
      multiMessages: [
        "Je m'appelle Julien",
        "J'ai été technicien micro-informatique",
        "J'ai développé une boutique en ligne eBay-like",
        "j'ai réalisé des scripts en JavaScript",
        "J'ai développé une plateforme de jeu de rôle multi-joueurs",
        'Je suis contributeur au travers de GitHUB',
        'Je suis développeur web junior',
      ],
    }
  },
}
</script>

<style scoped>
.containerBg {
  background-color: #163a58;
  height: auto;
  color: white;
}
article p {
  padding-left: 1.75em;
}
article .arrow {
  text-transform: uppercase;
}
article .arrow::before {
  content: '→ ';
}

.smoothNavigation {
  transition: 0.5s;
  position: absolute;
  right: 0;
  font-size: 3em;
  color: #ffc107;
}

.smoothNavigation:hover {
  color: gold;
  transition: 0.5s;
  transform: scale(1.5);
  cursor: pointer;
}
.top100px {
  top: 100px;
}
.top60px {
  top: 60px;
}
.resizeH4Carousel {
  font-size: 1.6vw;
}
/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran.
*/

@media screen and (min-width: 0px) and (max-width: 768px) {
  .resizeH4Carousel {
    font-size: 2.25vw;
  }
}
</style>
